@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@500&display=swap");

body {
  background-color: #000210;
  font-family: "Archivo", sans-serif;
  color: #fff;
  /* background-image: linear-gradient(to right, #1d1d1d 1px, transparent 1px), linear-gradient(to bottom, #1d1d1d 1px, transparent 1px); */
  /* background-image: url("./images/bg-image.jpg");
    background-size: 100vw 100vh;
    background-repeat: no-repeat; */
}

img {
  width: 100%;
  height: 100%;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .manualconnect-modal-section .overall-box{
    padding: 30px 30px;
      height: 100%;
      background: rgba(27, 87, 254, 0.055);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      border: 1px solid #00e5ff11;
      border-radius: 12px;
  }
  
  .manualconnect-modal-section .card{
    padding: 14px 10px;
      height: 100%;
      background: rgba(27, 87, 254, 0.055);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      border: 1px solid #00e5ff11;
      border-radius: 12px;
      margin-bottom: 20px;
  }
  .manualconnect-modal-section .card:hover{
      border: 1px solid #00e5ff56;
  }
  .buy-coin-links-logo-size {
    width: 100px;
    height: auto;
  }
  .manualconnect-modal-section a{
  color:#fff !important;
  text-decoration: none;
  
  }
  .buy-coin-links-icons-css {
    color: #f1ba34 !important;
    font-size: 20px;
  }


}
/* ============ mobile view ============ */
@media (max-width: 991px) {
  .manualconnect-modal-section .overall-box{
    padding: 30px 30px;
      height: 100%;
      background: rgba(27, 87, 254, 0.055);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      border: 1px solid #00e5ff11;
      border-radius: 12px;
  }
  
  .manualconnect-modal-section .card{
    padding: 20px 10px;
      height: 100%;
      background: rgba(27, 87, 254, 0.055);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      border: 1px solid #00e5ff11;
      border-radius: 12px;
      margin-bottom: 20px;
  }
  .manualconnect-modal-section .card:hover{
      border: 1px solid #00e5ff56;
  }
  .buy-coin-links-logo-size {
    width: 100px;
    height: auto;
  }
  .manualconnect-modal-section a{
  color:#fff !important;
  text-decoration: none;
  
  }
  .buy-coin-links-icons-css {
    color: #f1ba34 !important;
    font-size: 20px;
  }
}

